import http from "@/http";
import eventBus from "@/events";

const Company = {
    findAll: async function (ctx) {
        if(ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage || 20;
            const sortDesc = ctx.sortDesc ? ',desc' : '';
            const sort = (ctx.sortBy || 'name') + sortDesc;
            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, size, sort, sortDesc};
            const params = {...filter, ...pagination};
            const resp = await http("/companies/search/all", {params});
            eventBus.$emit('LIST_COUNT_COMPANY_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.companies;
        } else {
            const resp = await http("/companies/search/all");
            eventBus.$emit('LIST_COUNT_COMPANY_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.companies;
        }
    },
    create: function(data) {
        return http.post('/companies', data);
    },
    update: function (id, data) {
        return http.patch('/companies/' + id, data);
    },
    findById(id) {
        return http.get('/companies/' + id);
    },
    enable(id) {
        return http.patch('/companies/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/companies/' + id + '/disable')
    },
    deleteById(id) {
        return http.delete('/companies/' + id)
    },
    findCompanyTurns: function(id) { return http.get('undefined'.replace('{id}', id)); },findCompanyRoutes: function(id) { return http.get('undefined'.replace('{id}', id)); },findCompanyDrivers: function(id) { return http.get('undefined'.replace('{id}', id)); },findCompanyDieselControl: function(id) { return http.get('undefined'.replace('{id}', id)); },findCompanyTravels: function(id) { return http.get('undefined'.replace('{id}', id)); }
};

export default Company;
