import http from "@/http";
import eventBus from "@/events";

const Turn = {
    findAll: async function (ctx) {
        if(ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage || 20;
            const sortDesc = ctx.sortDesc ? ',desc' : '';
            const sort = (ctx.sortBy || 'economic') + sortDesc;
            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, size, sort, sortDesc};
            const params = {...filter, ...pagination};
            const resp = await http("/turns/search/all", {params});
            eventBus.$emit('LIST_COUNT_TURN_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.turns;
        } else {
            const resp = await http("/turns/search/all");
            eventBus.$emit('LIST_COUNT_TURN_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.turns;
        }
    },
    create: function(data) {
        return http.post('/turns', data);
    },
    update: function (id, data) {
        return http.patch('/turns/' + id, data);
    },
    findById(id) {
        return http.get('/turns/' + id);
    },
    enable(id) {
        return http.patch('/turns/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/turns/' + id + '/disable')
    },
    deleteById(id) {
        return http.delete('/turns/' + id)
    },
    findCompany: function(id) { return http.get('undefined'.replace('{id}', id)); },nameExists(name) {
        return http.get("/turns/exists/name", {params: {name: name}}).then(x => {
            return !x.data;
        });
    }
};

export default Turn;
