<template>
  <div>
    <div v-if="loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('COMPANIES')})  | capitalize}}</strong>
    </div>
    <b-select
        v-else
        id="company-input" type="text"
        :state="state"
        :value="content"
        @change="handleInput" class="w-100">
      <b-select-option :value="undefined">{{$t(undefinedMeans, {gender: 'a', catalog: $t('COMPANIES')}) | capitalize}}</b-select-option>
      <b-select-option :key="item.id" v-for="item in items" :value="valueType === 'id' ? item.id : item._links.self.href">{{$t(item.name)}}</b-select-option>
    </b-select>
    <b-form-invalid-feedback id="company-input-feedback">
      <div v-for="error in errors" :key="error.error">
        {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('COMPANY')},})  | capitalize }}
      </div>
    </b-form-invalid-feedback>
  </div>

</template>

<script>
import {VModel, SendsMessages} from "@/mixins";
import Company from "@/companies/index";

export default {
  name: "CompanySingleSelector",
  props: ['state', 'errors', 'valueType', 'undefinedMeans'],
  mixins: [VModel, SendsMessages],
  methods: {
    handleInput(val) {
      this.$emit('input', val);
      let test = null;
      if(this.valueType === 'id') {
        test = this.items.find(item => item.id === val)
      } else {
        test = this.items.find(item => item._links.self.href === val);
      }
      this.$emit('instance', test);
    },
  },
  async mounted() {
    try {
      this.loading = true;
      this.items = await Company.findAll({perPage: 1000, sortBy: 'name', filter: {status: true}});
    } catch(e) {
      this.sendError('ERROR_LOADING_CATALOG', {}, e);
    } finally {
      this.loading = false;
    }
  },
  data() {
    return {
      loading: false,
      items: []
    }
  }
}
</script>

<style scoped>

</style>
